<template>
    <div class="card card-default" :class="loading ? 'whirl' : ''">
        <div class="card-header bg-gray-lighter text-bold">{{$t('common.view.settings.notifications.heading')}}</div>
        <div class="card-body">

            <div class="mb-3 font-weight-bold">{{$t('common.view.settings.notifications.device.heading')}}</div>

            <div v-if="isSupported()">
                <span class="text-primary">{{$t('common.view.settings.notifications.device.support.true')}}</span>

                <div v-show="notification_permission === 'granted'"><span>{{$t('common.view.settings.notifications.device.notification.status')}} </span><span class="text-primary">{{$t('common.view.settings.notifications.device.notification.enabled')}}</span>
                </div>
                <div v-show="notification_permission !== 'granted'"><span>{{$t('common.view.settings.notifications.device.notification.status')}} </span><span class="text-danger">{{$t('common.view.settings.notifications.device.notification.disabled')}}</span>

                    <div class="mt-2">{{$t('common.view.settings.notifications.device.notification.check')}} <span class="b bg-white p-1 pl-2 text-nowrap"><i class="fas fa-info-circle mr-1"></i><i
                            class="fas fa-lock mr-2"></i> https://ca...</span> {{$t('common.view.settings.notifications.device.notification.check_end')}}
                    </div>
                </div>

                <b-btn class="mt-2" @click="test()">{{$t('common.view.settings.notifications.device.notification.recheck')}}</b-btn>

            </div>
            <div v-if="!isSupported()">
                <span class="text-danger">{{$t('common.view.settings.notifications.device.support.false')}}</span>
            </div>

            <hr>

            <div class="mb-3 mt-3 font-weight-bold">{{ $t('common.view.settings.notifications.telegram.heading') }}</div>

            <div v-if="settings.telegram_id">
              {{ $t('common.view.settings.notifications.telegram.text_status') }}: <span class="text-primary">{{$t('common.view.settings.notifications.device.notification.enabled')}}</span>
            </div>
            <div v-else>
              {{ $t('common.view.settings.notifications.telegram.text_status') }}: <span class="text-danger">{{$t('common.view.settings.notifications.device.notification.disabled')}}</span><br/>

                <b-btn class="mt-2" variant="primary" @click="enableTelegramNotifications()">{{ $t('common.view.settings.notifications.telegram.button.enable.label') }}</b-btn>

            </div>

            <hr>

            <div class="mb-3 mt-3 font-weight-bold">{{$t('common.view.settings.notifications.email.heading')}}</div>

            <b-list-group>
                <b-list-group-item>

                    <label class="switch d-inline mr-2">
                        <input type="checkbox" checked="checked" v-model="settings.mail_notify_new_member"/>
                        <span></span>
                    </label>

                    <span>{{$t('common.view.settings.notifications.email.notification.new_member')}}</span>
                </b-list-group-item>

                <b-list-group-item>

                    <label class="switch d-inline mr-2">
                        <input type="checkbox" checked="checked" v-model="settings.mail_notify_commission"/>
                        <span></span>
                    </label>

                    <span>{{$t('common.view.settings.notifications.email.notification.comission')}}</span>
                </b-list-group-item>

                <b-list-group-item>

                    <label class="switch d-inline mr-2">
                        <input type="checkbox" checked="checked" v-model="settings.mail_notify_general"/>
                        <span></span>
                    </label>

                    <span>{{$t('common.view.settings.notifications.email.notification.general')}}</span>
                </b-list-group-item>

                <b-list-group-item>

                    <label class="switch d-inline mr-2">
                        <input type="checkbox" checked="checked" v-model="settings.mail_notify_members"/>
                        <span></span>
                    </label>

                    <span>{{$t('common.view.settings.notifications.email.notification.members')}}</span>
                </b-list-group-item>
                <b-list-group-item>

                    <label class="switch d-inline mr-2">
                        <input type="checkbox" checked="checked" v-model="settings.mail_notify_newsletter"/>
                        <span></span>
                    </label>

                    <span>{{$t('common.view.settings.notifications.email.notification.newsletter')}}</span>
                </b-list-group-item>
                <b-list-group-item>

                    <label class="switch d-inline mr-2">
                        <input type="checkbox" checked="checked" v-model="settings.mail_notify_chat_broadcast_message"/>
                        <span></span>
                    </label>

                    <span>{{$t('common.view.settings.notifications.email.notification.chat_broadcast_message')}}</span>
                </b-list-group-item>
                <b-list-group-item>

                    <label class="switch d-inline mr-2">
                        <input type="checkbox" checked="checked" v-model="settings.mail_notify_chat_direct_message"/>
                        <span></span>
                    </label>

                    <span>{{$t('common.view.settings.notifications.email.notification.chat_direct_message')}}</span>
                </b-list-group-item>
            </b-list-group>

            <b-btn-toolbar class="justify-content-end mt-3">
                <b-btn variant="primary" @click="save()">{{$t('common.view.settings.notifications.email.notification.save')}}</b-btn>
            </b-btn-toolbar>

        </div>
    </div>
</template>
<style>

</style>
<script>

    export default {
        components: {},
        data() {
            return {
                loading: false,
                notification_permission: '',
                settings: {
                    mail_notify_new_member: 0,
                    mail_notify_commission: 0,
                    mail_notify_newsletter: 0,
                    mail_notify_general: 0,
                    mail_notify_members: 0,
                    mail_notify_chat_broadcast_message: 0,
                    mail_notify_chat_direct_message: 0,
                }
            }
        },
        beforeCreate() {
            this.$api.get('user/settings').then(response => {
                this.settings = response.data;
            })
        },
        mounted() {
            this.checkPermission();
        },
        methods: {
            enableTelegramNotifications() {
                this.$api.post('user/request-telegram-verification').then(response => {
                    window.open(response.data.redirect);
                });
            },
            isSupported() {
                return !((!window.Notification && !navigator.mozNotification) || !window.FileReader || !window.history.pushState);
            },
            checkPermission() {
                if (window.Notification) {
                    this.notification_permission = Notification.permission;
                }
            },
            save() {
                this.loading = true;
                this.$api.update('user/settings', this.settings).then((response) => {
                    this.loading = false;
                    this.settings = response.data;
                    this.$swal.fire(this.$t('common.popup.saved.title'), '','success');
                });
            },
            test() {
                this.$fcm.showNotification('Congratulations', {
                    body: 'It works, you will receive now notifications if your application is active in background.',
                    icon: '/img/logo/cannerald-notification-192x192.png'
                });

                this.checkPermission();
            }

        }
    }
</script>